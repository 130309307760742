<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'遥监设备详情':'遥监设备详情'"
    width="1200px"
    :modal="false"
    top="3vh"
    @close="$reset('form')">
    <el-button type="primary" style="margin-bottom: 10px;" @click="exportExcelData">导出</el-button>
    <vm-table
      v-show="!value1"
      ref="vmTable"
      :filter.sync="filter"
      :immediately="true"
      url="elevator-iot/iot-device-online-log/page">
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column label="在线状态" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.online?'success':'info'">{{scope.row.online?'在线':'离线'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="数据发生时间" align="center" prop="timestamp">
      </el-table-column>
      <el-table-column label="数据保存时间" align="center" prop="saveTime">
      </el-table-column>
    </vm-table>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  import {export2Excel} from "@/util";
  import moment from "moment";

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        id:"",
        value1:false,
        yaixs:[],
        formatData:{
          xaxis: [],
          data:[],
          record:[],
        },
        filter: {
          no: "",
          source: "",
          isp: null,
          iccid: "",
          orderColumnName:"timestamp",
          asc:0,
        },
        model:{},
      };
    },
    methods: {
      open(row) {
        this.iotDeviceId = row.iotDeviceId;
        this.filter.iotDeviceId= row.iotDeviceId;
        this.model.iotDeviceId = row.iotDeviceId;
        this.dialogVisible = true;
        this.row = row;
        this.getList(1);
      },
      getList(pageNum) {
        let time=window.setTimeout(()=>{
          this.$refs.vmTable.getList(pageNum);
          window.clearTimeout(time);
        },200);

      },

      exportExcelData() {
        const title={

          online:"是否在线",
          saveTime:"发生时间",

        };
        console.log(this.row,88);
        this.$http.get("elevator-iot/iot-device-online-log/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`维修数据统计-${this.row.realEstateName+this.row.buildingName+this.row.elevatorName+this.row.iotDeviceName}-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (!this.model.isp) {
              this.model.isp=null;
            }
            this.$http
              .save("elevator-iot/device", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      realEstateSelect(row) {
        this.$set(this.model,"realEstateId", row.id);
        this.$set(this.model,"realEstateName", row.name);

      },
      buildingSelect(row) {
        this.$set(this.model,"buildingId", row.id);
        this.$set(this.model,"buildingName", row.name);

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
