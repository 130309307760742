<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :pagination-page-size="20"
      url="data-statistics/elevator-online-offline-statistics/page">
      <el-button
        v-if="$auth('维修统计导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model="filter.realEstateName"></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model="filter.buildingName"></el-input>
          </vm-search>

          <vm-search label="时间范围">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>

      <el-table-column prop="realEstateName" label="楼盘" align="center" width="170">
      </el-table-column>
      <el-table-column prop="buildingName" label="楼宇" align="center" width="170"></el-table-column>
      <el-table-column prop="elevatorName" label="电梯名称" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="elevatorOnline" label="电梯是否在线" align="center" width="100">
        <template slot-scope="socpe">
          {{socpe.row.elevatorOnline?"是":'否'}}
        </template>
      </el-table-column>
      <el-table-column prop="iotDeviceName" label="设备名称" align="center" width="170"></el-table-column>
      <el-table-column prop="modelDesc" label="设备型号" align="center" width="170"></el-table-column>

      <el-table-column prop="iotOnline" label="设备是否在线" align="center" width="100">
        <template slot-scope="socpe">
          {{socpe.row.iotOnline?"是":'否'}}
        </template>
      </el-table-column>
      <el-table-column prop="onlineTimes" label="上线次数" align="center">
        <template slot="header" slot-scope="">
          上线次数 <el-button type="text" icon="el-icon-sort" @click="sort('onlineTimes')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="offlineTimes" label="离线次数" align="center">
        <template slot="header" slot-scope="">
          离线次数 <el-button type="text" icon="el-icon-sort" @click="sort('offlineTimes')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="lastHeartbeatTime" label="最后心跳时间" align="center">
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('遥监设备详情')" type="primary" @click="goToDetail(scope.row)">{{$l("common.edit", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <elevator-online-count-listdetail ref="ElevatorOnlineCountListdetail" @save-success="getList(-1)"></elevator-online-count-listdetail>
  </div>
</template>
<script>
  import {export2Excel} from "@/util";
  import moment from "moment";
  import ElevatorOnlineCountListdetail from "./ElevatorOnlineCountListdetail.vue";
  // import loginUtil from "@/util/loginUtil";

  export default {
    components: {ElevatorOnlineCountListdetail},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          maintenanceType:"as_need",
          // companyId: loginUtil.getCompanyCode(),
          realEstateName:"",
          arr:[{
            orderColumnName:"",
            asc:1,
          }],
          orderColumnNames:"",
        },
        senior: false,
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.timestampFrom = this.filter.seTime[0];
          this.filter.timestampTo = this.filter.seTime[1];
        }
      },
      exportExcelData() {
        const title={

          realEstateName:"楼盘名称",
          buildingName:"楼宇名称",
          elevatorName:"电梯名称",
          regCode:"注册代码",
          elevatorOnline:"电梯是否在线",
          iotDeviceName:"设备名称",
          modelDesc:"设备型号",
          iotOnline:"设备是否在线",

          onlineTimes:"上线次数",
          offlineTimes:"离线次数",
          lastHeartbeatTime:"最后心跳时间",
        };
        this.$http.get("data-statistics/elevator-online-offline-statistics/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`维修数据统计-上下线次数-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      goToDetail(row) {
        this.$refs.ElevatorOnlineCountListdetail.open(row);
      },


      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>
